import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  FaFacebook,
  FaInstagram,
  FaTwitter,
  FaLeaf,
  FaFire,
  FaCalendarAlt,
  FaClock,
} from 'react-icons/fa';
import { Swiper, SwiperSlide } from 'swiper/react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { EffectCoverflow, Pagination, Navigation } from 'swiper/modules';
import './LandingPage.css';
import heroImage from '../assets/hero_image.png';
import introImage from '../assets/intro_image.png';
import introImage1 from '../assets/intro_image1.jpg';
import introImage2 from '../assets/intro_image2.jpg';
import menuImage1 from '../assets/menu_image1.jpg';
import menuImage2 from '../assets/menu_image2.jpg';
import menuImage3 from '../assets/menu_image3.jpg';
import testimonialAvatar1 from '../assets/testimonial_avatar1.jpg';
import testimonialAvatar2 from '../assets/testimonial_avatar2.jpg';
import galleryImage1 from '../assets/gallery_image1.jpg';
import galleryImage2 from '../assets/gallery_image2.jpg';
import galleryImage3 from '../assets/gallery_image3.jpg';
import galleryImage4 from '../assets/gallery_image4.jpg';
import logoImage from '../assets/logo-removebg.png';

const LandingPage = () => {
  const navigate = useNavigate();
  const galleryImages = [
    galleryImage1,
    galleryImage2,
    galleryImage3,
    galleryImage4,
  ];

  const handleOrderNow = () => {
    navigate('/auth?redirectTo=/products');
  };

  const menuItems = [
    {
      image: menuImage1,
      name: 'Tacos al Pastor',
      description: 'Deliciosos tacos con carne de cerdo marinada y piña',
      vegetarian: false,
      spicy: true,
    },
    {
      image: menuImage2,
      name: 'Enchiladas Verdes',
      description: 'Enchiladas rellenas de pollo y cubiertas con salsa verde',
      vegetarian: false,
      spicy: false,
    },
    {
      image: menuImage3,
      name: 'Guacamole y Chips',
      description: 'Guacamole casero servido con crujientes totopos de maíz',
      vegetarian: true,
      spicy: false,
    },
  ];

  const testimonials = [
    {
      avatar: testimonialAvatar1,
      quote: '¡La mejor comida mexicana que he probado!',
      author: 'Juan Pérez',
    },
    {
      avatar: testimonialAvatar2,
      quote: 'Servicio excepcional y platos auténticos',
      author: 'María García',
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    centerMode: false,
    adaptiveHeight: true,
  };

  return (
    <div className='landing-page'>
      <header>
        <div className='logo'>
          <img src={logoImage} alt='Logo' />
        </div>
        <div className='hero'>
          <img src={heroImage} alt='Hero' />
          <div className='hero-content'>
            <h1>Experimenta los Sabores de México</h1>
            <p>
              Disfruta nuestra comida al estilo mexicano,
              <br />
              nutritiva y 100% artesanal
            </p>
            <button className='btn btn-primary' onClick={handleOrderNow}>
              Ordena Ahora
            </button>
          </div>
        </div>
      </header>
      <main>
        <section className='intro'>
          <div className='intro-content'>
            <div className='welcome-header'>
              <div className='welcome-text'>
                <h2>Bienvenido a Q&apos;inti</h2>
                <h2>El Rincón Enchilado</h2>
              </div>
              <img
                src={introImage}
                alt="Logo de Q'inti"
                className='logo-image'
              />
            </div>
            <p>
              Descubre los ricos sabores y la vibrante cultura de México a
              través de nuestros platos cuidadosamente elaborados. Desde los
              favoritos tradicionales hasta creaciones contemporáneas, nuestro
              menú muestra lo mejor de la cocina mexicana, hecha a mano con
              ingredientes frescos y nutritivos.
            </p>
          </div>
          <div className='intro-images'>
            <img src={introImage1} alt='Intro 1' />
            <img src={introImage2} alt='Intro 2' />
          </div>
        </section>
        <section className='menu'>
          <h2>Nuestro Menú</h2>
          <div className='menu-items'>
            {menuItems.map((item, index) => (
              <div className='menu-item' key={index}>
                <img src={item.image} alt={item.name} />
                <h3>{item.name}</h3>
                <p>{item.description}</p>
                <div className='menu-item-tags'>
                  {item.vegetarian && (
                    <span className='tag vegetarian'>
                      <FaLeaf /> Vegetariano
                    </span>
                  )}
                  {item.spicy && (
                    <span className='tag spicy'>
                      <FaFire /> Picante
                    </span>
                  )}
                </div>
              </div>
            ))}
          </div>
        </section>
        <section className='testimonials'>
          <h2>Lo que Dicen Nuestros Clientes</h2>
          <div className='testimonial-carousel'>
            <Slider {...settings}>
              {testimonials.map((testimonial, index) => (
                <div className='testimonial-item' key={index}>
                  <img src={testimonial.avatar} alt={`Cliente ${index + 1}`} />
                  <p>{`"${testimonial.quote}"`}</p>
                  <p className='testimonial-author'>{`- ${testimonial.author}`}</p>
                </div>
              ))}
            </Slider>
          </div>
        </section>
        <section className='gallery'>
          <h2>Galería</h2>
          <Swiper
            modules={[EffectCoverflow, Pagination, Navigation]}
            effect='coverflow'
            grabCursor={true}
            centeredSlides={true}
            loop={true}
            breakpoints={{
              320: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              480: {
                slidesPerView: 3,
                spaceBetween: 15,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 25,
              },
              1440: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              1920: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
            }}
            coverflowEffect={{
              rotate: 0,
              stretch: 0,
              depth: 100,
              modifier: 2.5,
              slideShadows: false,
            }}
            pagination={{ clickable: true }}
            navigation
            className='swiper-container'
          >
            {galleryImages.map((image, index) => (
              <SwiperSlide key={index}>
                <img src={image} alt={`Galería ${index + 1}`} />
              </SwiperSlide>
            ))}
          </Swiper>
        </section>
        <section className='reservation'>
          <h2>Haz una Reservación</h2>
          <form>
            <div className='input-wrapper'>
              <input type='text' placeholder='Nombre' required />
            </div>
            <div className='input-wrapper'>
              <input type='email' placeholder='Correo Electrónico' required />
            </div>
            <div className='input-wrapper'>
              <input type='tel' placeholder='Teléfono' required />
            </div>
            <div className='input-wrapper'>
              <input type='date' id='fecha' placeholder='Fecha' required />
              <label htmlFor='fecha'>
                <FaCalendarAlt
                  style={{
                    position: 'absolute',
                    top: '50%',
                    right: '10px',
                    transform: 'translateY(-50%)',
                    width: '20px',
                    height: '20px',
                    color: '#777',
                    cursor: 'pointer',
                  }}
                />
              </label>
            </div>
            <div className='input-wrapper'>
              <input type='time' id='hora' placeholder='Hora' required />
              <label htmlFor='hora'>
                <FaClock
                  style={{
                    position: 'absolute',
                    top: '50%',
                    right: '10px',
                    transform: 'translateY(-50%)',
                    width: '20px',
                    height: '20px',
                    color: '#777',
                    cursor: 'pointer',
                  }}
                />
              </label>
            </div>
            <button type='submit' className='btn btn-primary'>
              Enviar
            </button>
          </form>
        </section>
      </main>
      <footer>
        <div className='footer-content'>
          <div className='footer-section'>
            <h3>Contáctanos</h3>
            <p>Bourgeois N3439</p>
            <p>Quito, Ecuador</p>
            <p>Teléfono: 0959644230</p>
            <p>Email: contacto@elrincon-enchilado.fun</p>
          </div>
          <div className='footer-section'>
            <h3>Síguenos</h3>
            <div className='social-icons'>
              <a href='https://www.facebook.com'>
                <FaFacebook />
              </a>
              <a href='https://www.instagram.com'>
                <FaInstagram />
              </a>
              <a href='https://www.twitter.com'>
                <FaTwitter />
              </a>
            </div>
          </div>
        </div>
        <div className='footer-bottom'>
          <p>
            &copy; 2024 Alimentos Estilo Mexicano. Todos los derechos
            reservados.
          </p>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;
