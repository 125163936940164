import React, { useEffect, useCallback } from 'react';
import './ProductsGrid.css';
import PropTypes from 'prop-types';

const ProductsGrid = ({
  products,
  selectedProduct,
  setSelectedProduct,
  resetSelectedProducts,
  selectedProducts,
  toggleProductSelection,
}) => {
  useEffect(() => {
    if (!selectedProduct) {
      resetSelectedProducts();
    } else {
      // Solución para el hover persistente
      const hoverReset = setTimeout(() => {
        const hoveredElement = document.querySelector(':hover');
        if (hoveredElement) {
          const parent = hoveredElement.closest('.product-grid-card');
          if (parent) {
            parent.style.pointerEvents = 'none';
            setTimeout(() => {
              parent.style.pointerEvents = '';
            }, 0);
          }
        }
      }, 0);

      return () => clearTimeout(hoverReset);
    }
  }, [selectedProduct, resetSelectedProducts]);

  const handleProductClick = useCallback(
    (product) => {
      if (product.parent_id) {
        toggleProductSelection(product);
      } else {
        const subProducts = products.filter(
          (subProduct) => subProduct.parent_id === product.id
        );
        if (subProducts.length > 0) {
          setSelectedProduct(product);
        } else {
          toggleProductSelection(product);
        }
      }
    },
    [products, setSelectedProduct, toggleProductSelection]
  );

  const renderProductCard = useCallback(
    (product) => (
      <div
        key={product.id}
        className={`product-grid-card ${product.id in selectedProducts ? 'selected' : ''}`}
        onClick={() => handleProductClick(product)}
      >
        <div className='image-container'>
          <img src={product.image} alt={product.name} />
        </div>
        <div className='product-grid-info'>
          <h3>{product.name}</h3>
          <p>Precio: ${product.price ? product.price.toFixed(2) : 'N/A'}</p>
        </div>
        {product.id in selectedProducts && (
          <div className='added-banner'>
            Añadido ({selectedProducts[product.id]})
          </div>
        )}
      </div>
    ),
    [selectedProducts, handleProductClick]
  );

  const renderSubProducts = useCallback(() => {
    if (!selectedProduct) return null;

    const subProducts = products.filter(
      (product) => product.parent_id === selectedProduct.id
    );

    if (subProducts.length === 0) {
      return <p>No hay opciones disponibles para este producto.</p>;
    }

    return (
      <div className='subproducts-grid'>
        <button
          className='back-button'
          onClick={() => setSelectedProduct(null)}
        >
          <i className='fas fa-arrow-left'></i>
        </button>
        {subProducts.map((subProduct) => (
          <div
            key={subProduct.id}
            className={`product-grid-card ${subProduct.id in selectedProducts ? 'selected' : ''}`}
            onClick={() => handleProductClick(subProduct)}
          >
            <div className='image-container'>
              <img src={subProduct.image} alt={subProduct.name} />
            </div>
            <div className='product-grid-info'>
              <h3>{subProduct.name}</h3>
              <p>
                Precio: $
                {subProduct.price ? subProduct.price.toFixed(2) : 'N/A'}
              </p>
            </div>
            {subProduct.id in selectedProducts && (
              <div className='added-banner'>
                Añadido ({selectedProducts[subProduct.id]})
              </div>
            )}
          </div>
        ))}
      </div>
    );
  }, [
    selectedProduct,
    products,
    setSelectedProduct,
    selectedProducts,
    handleProductClick,
  ]);

  return (
    <div
      className={`products-container ${selectedProduct ? 'showing-subproducts' : ''}`}
    >
      {selectedProduct ? (
        renderSubProducts()
      ) : (
        <div className='products-grid'>
          {products
            .filter((product) => !product.parent_id)
            .map(renderProductCard)}
        </div>
      )}
    </div>
  );
};

ProductsGrid.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      price: PropTypes.number,
      image: PropTypes.string.isRequired,
      parent_id: PropTypes.number,
    })
  ).isRequired,
  selectedProduct: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    price: PropTypes.number,
    image: PropTypes.string,
    parent_id: PropTypes.number,
  }),
  setSelectedProduct: PropTypes.func.isRequired,
  resetSelectedProducts: PropTypes.func.isRequired,
  selectedProducts: PropTypes.object.isRequired,
  toggleProductSelection: PropTypes.func.isRequired,
};

export default ProductsGrid;
