import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaCheckCircle } from 'react-icons/fa'; // Importar el icono de react-icons
import '../components/Confirmation.css';

const Confirmacion = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { orderId, totalAmount } = location.state || {
    orderId: '',
    totalAmount: 0,
  };

  return (
    <div className='confirmacion-container'>
      <div className='confirmacion-content'>
        <div className='icon-container'>
          <FaCheckCircle className='icon-inner' />{' '}
          {/* Usar el icono de react-icons */}
        </div>
        <h2 className='confirmacion-title'>Orden Confirmada</h2>
        <p className='confirmacion-monto'>ID de la Orden: {orderId}</p>
        <p className='confirmacion-monto'>
          Monto Orden: ${totalAmount.toFixed(2)}
        </p>
        <hr className='divider' />
        <button className='btn btn-primary' onClick={() => navigate('/')}>
          Ir al inicio
        </button>
      </div>
    </div>
  );
};

export default Confirmacion;
