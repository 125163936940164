// src/components/ConfirmationModalStyles.js

import styled, { keyframes } from 'styled-components';
import { FaThumbsUp, FaTimesCircle } from 'react-icons/fa';

// Animación para el fadeIn
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

// Estilos del modal
export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${fadeIn} 0.3s ease-out;
  z-index: 10000; /* Ajuste del z-index */
`;

export const ModalContent = styled.div`
  background: #fff;
  border-radius: 12px; /* Más redondeado */
  padding: 15px; /* Reducción del padding */
  max-width: 600px;
  width: 100%;
  max-height: 90vh;
  overflow-y: auto;
  z-index: 10001; /* Ajuste del z-index */
`;

// Contenedor y iconos
export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 15px; /* Reducción del margin */
`;

export const IconCheck = styled(FaThumbsUp)`
  color: #4caf50;
  font-size: 48px;
`;

export const IconCancel = styled(FaTimesCircle)`
  color: #f44336;
  font-size: 48px;
`;

// Lista de órdenes
export const OrderList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const OrderItem = styled.li`
  margin-bottom: 8px; /* Reducción del margin */
`;

// Total
export const TotalAmount = styled.p`
  font-weight: bold;
`;

// Información del cliente
export const CustomerInfo = styled.div`
  margin-top: 15px; /* Reducción del margin */

  h3 {
    margin-bottom: 10px;
    color: #333;
  }
`;

export const InputLabel = styled.label`
  display: block;
  margin-bottom: 8px; /* Reducción del margin */
`;

export const Input = styled.input`
  width: 100%;
  padding: 6px; /* Reducción del padding */
  border: 1px solid #ccc;
  border-radius: 8px; /* Más redondeado */
  margin-top: 4px; /* Reducción del margin */
  font-size: 14px;
`;

export const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 8px; /* Reducción del margin */
`;

export const Checkbox = styled.input`
  margin-right: 8px;
`;

// Mensaje de error
export const ErrorMessage = styled.p`
  color: #f44336;
  margin-top: 10px;
`;

// Botones del modal
export const ModalButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px; /* Reducción del margin */
`;

export const Button = styled.button`
  background-color: ${(props) => (props.$confirm ? '#4caf50' : '#f44336')};
  color: #fff;
  border: none;
  padding: 8px 16px; /* Reducción del padding */
  border-radius: 8px; /* Más redondeado */
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;

  &:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }

  svg {
    margin-left: 8px;
  }
`;

// Spinner para carga
export const Spinner = styled.div`
  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
