import React, { useState } from 'react';
import { supabase } from '../supabaseClient';
import { useNavigate } from 'react-router-dom';
import {
  AuthContainer,
  AuthLogo,
  AuthTitle,
  AuthInputWrapper,
  AuthInput,
  AuthButton,
  AuthErrorMessage,
  AuthLink,
  AuthFooter,
  PasswordToggle,
} from './AuthStyles';
import logoImage from '../assets/logo-removebg.png';

const Auth = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [authMode, setAuthMode] = useState('signIn');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleSuccessfulSignIn = () => {
    navigate('/products');
    console.log('Inicio de sesión exitoso');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      if (authMode === 'signIn') {
        await handleSignIn();
      } else if (authMode === 'signUp') {
        await handleSignUp();
      } else if (authMode === 'forgotPassword') {
        await handleForgotPassword();
      }
    } catch (error) {
      setError(error.message);
    }

    setLoading(false);
  };

  const handleSignIn = async () => {
    const { error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });

    if (error) {
      throw error;
    } else {
      handleSuccessfulSignIn();
    }
  };

  const handleSignUp = async () => {
    const { error } = await supabase.auth.signUp({ email, password });

    if (error) {
      throw error;
    } else {
      setAuthMode('signIn');
      console.log(
        'Registro exitoso. Por favor, revisa tu email para confirmar tu cuenta.'
      );
    }
  };

  const handleForgotPassword = async () => {
    const { error } = await supabase.auth.resetPasswordForEmail(email);

    if (error) {
      throw error;
    } else {
      setAuthMode('signIn');
      console.log(
        'Email de restablecimiento enviado. Por favor, revisa tu bandeja de entrada.'
      );
    }
  };

  const togglePasswordVisibility = (e) => {
    e.preventDefault(); // Evitar el comportamiento predeterminado del botón
    setShowPassword(!showPassword);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setError(null);
  };

  return (
    <AuthContainer>
      <AuthLogo>
        <img src={logoImage} alt='Logo' />
      </AuthLogo>
      <AuthTitle>
        {authMode === 'signIn'
          ? 'Iniciar Sesión'
          : authMode === 'signUp'
            ? 'Regístrate'
            : 'Restablecer Contraseña'}
      </AuthTitle>
      <form onSubmit={handleSubmit}>
        <AuthInputWrapper>
          <label htmlFor='email' className='sr-only'>
            Correo Electrónico
          </label>
          <AuthInput
            id='email'
            name='email'
            type='email'
            placeholder='Correo Electrónico'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            autoComplete='email'
          />
        </AuthInputWrapper>
        {authMode !== 'forgotPassword' && (
          <AuthInputWrapper>
            <label htmlFor='password' className='sr-only'>
              Contraseña
            </label>
            <AuthInput
              id='password'
              name='password'
              type={showPassword ? 'text' : 'password'}
              placeholder='Contraseña'
              value={password}
              onChange={handlePasswordChange}
              required
              autoComplete={
                authMode === 'signUp' ? 'new-password' : 'current-password'
              }
            />
            <PasswordToggle
              type='button'
              onClick={togglePasswordVisibility}
              aria-label={
                showPassword ? 'Ocultar contraseña' : 'Mostrar contraseña'
              }
            >
              <i
                className={showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}
              ></i>
            </PasswordToggle>
          </AuthInputWrapper>
        )}
        {error && <AuthErrorMessage>{error}</AuthErrorMessage>}
        <AuthButton type='submit' disabled={loading}>
          {loading
            ? 'Cargando...'
            : authMode === 'signIn'
              ? 'Iniciar Sesión'
              : authMode === 'signUp'
                ? 'Regístrate'
                : 'Enviar Email de Restablecimiento'}
        </AuthButton>
      </form>
      <AuthFooter>
        {authMode === 'signIn' ? (
          <>
            <p>
              ¿No tienes una cuenta?{' '}
              <AuthLink onClick={() => setAuthMode('signUp')}>
                Regístrate
              </AuthLink>
            </p>
            <p>
              ¿Olvidaste tu contraseña?{' '}
              <AuthLink onClick={() => setAuthMode('forgotPassword')}>
                Restablecer
              </AuthLink>
            </p>
          </>
        ) : authMode === 'signUp' ? (
          <p>
            ¿Ya tienes una cuenta?{' '}
            <AuthLink onClick={() => setAuthMode('signIn')}>
              Iniciar Sesión
            </AuthLink>
          </p>
        ) : (
          <p>
            ¿Recordaste tu contraseña?{' '}
            <AuthLink onClick={() => setAuthMode('signIn')}>
              Iniciar Sesión
            </AuthLink>
          </p>
        )}
      </AuthFooter>
    </AuthContainer>
  );
};

export default Auth;
