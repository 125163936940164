// src/components/ConfirmationModal.js

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  ModalWrapper,
  ModalContent,
  IconContainer,
  IconCheck,
  IconCancel,
  OrderList,
  OrderItem,
  TotalAmount,
  CustomerInfo,
  InputLabel,
  Input,
  CheckboxLabel,
  Checkbox,
  ErrorMessage,
  ModalButtons,
  Button,
  Spinner,
} from './ConfirmationModalStyles';

const ConfirmationModal = ({
  cart,
  calculateTotal,
  confirmOrder,
  closeModal,
  isConfirmingOrder,
}) => {
  const [error, setError] = useState(null);
  const [customerData, setCustomerData] = useState({
    name: 'Consumidor final',
    cedula: '1111111111',
    telefono: 'N/A',
    direccion: 'N/A',
    email: 'N/A',
  });
  const [isFinalConsumer, setIsFinalConsumer] = useState(true);

  const handleConfirmOrder = async () => {
    setError(null);
    try {
      await confirmOrder(customerData);
      closeModal();
    } catch (err) {
      setError(
        'Hubo un problema al confirmar la orden. Por favor, inténtalo de nuevo.'
      );
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCustomerData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCheckboxChange = () => {
    setIsFinalConsumer(!isFinalConsumer);
    if (!isFinalConsumer) {
      setCustomerData({
        name: '',
        cedula: '',
        telefono: '',
        direccion: '',
        email: '',
      });
    } else {
      setCustomerData({
        name: 'Consumidor final',
        cedula: '1111111111',
        telefono: 'N/A',
        direccion: 'N/A',
        email: 'N/A',
      });
    }
  };

  return (
    <ModalWrapper>
      <ModalContent>
        <IconContainer>
          <IconCheck />
        </IconContainer>
        <h2>Confirmar Orden</h2>
        <OrderList>
          {cart.map((item) => (
            <OrderItem key={item.id}>
              {item.name} - Cantidad: {item.quantity} - Precio: $
              {item.price.toFixed(2)}
            </OrderItem>
          ))}
        </OrderList>
        <TotalAmount>Total: ${calculateTotal().toFixed(2)}</TotalAmount>

        <CustomerInfo>
          <h3>Datos de Factura</h3>
          <CheckboxLabel>
            <Checkbox
              type='checkbox'
              checked={isFinalConsumer}
              onChange={handleCheckboxChange}
            />
            Consumidor Final
          </CheckboxLabel>
          {!isFinalConsumer && (
            <>
              <InputLabel>
                Nombre:
                <Input
                  type='text'
                  name='name'
                  value={customerData.name}
                  onChange={handleInputChange}
                  disabled={isFinalConsumer}
                />
              </InputLabel>
              <InputLabel>
                Cédula:
                <Input
                  type='text'
                  name='cedula'
                  value={customerData.cedula}
                  onChange={handleInputChange}
                  disabled={isFinalConsumer}
                />
              </InputLabel>
              <InputLabel>
                Teléfono:
                <Input
                  type='text'
                  name='telefono'
                  value={customerData.telefono}
                  onChange={handleInputChange}
                  disabled={isFinalConsumer}
                />
              </InputLabel>
              <InputLabel>
                Dirección:
                <Input
                  type='text'
                  name='direccion'
                  value={customerData.direccion}
                  onChange={handleInputChange}
                  disabled={isFinalConsumer}
                />
              </InputLabel>
              <InputLabel>
                Correo electrónico:
                <Input
                  type='email'
                  name='email'
                  value={customerData.email}
                  onChange={handleInputChange}
                  disabled={isFinalConsumer}
                />
              </InputLabel>
            </>
          )}
        </CustomerInfo>

        {error && <ErrorMessage>{error}</ErrorMessage>}
        <ModalButtons>
          <Button
            $confirm={isConfirmingOrder.toString()}
            onClick={handleConfirmOrder}
            disabled={isConfirmingOrder}
          >
            {isConfirmingOrder ? (
              <>
                <Spinner /> Procesando...
              </>
            ) : (
              <>
                Confirmar <IconCheck />
              </>
            )}
          </Button>
          <Button onClick={closeModal} disabled={isConfirmingOrder}>
            Cancelar <IconCancel />
          </Button>
        </ModalButtons>
      </ModalContent>
    </ModalWrapper>
  );
};

ConfirmationModal.propTypes = {
  cart: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
      quantity: PropTypes.number.isRequired,
    })
  ).isRequired,
  calculateTotal: PropTypes.func.isRequired,
  confirmOrder: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  isConfirmingOrder: PropTypes.bool.isRequired,
};

export default ConfirmationModal;
