import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import AuthContext from './AuthContext';

export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    session: null,
    role: null,
    loading: true,
  });

  useEffect(() => {
    const fetchSessionAndRole = async () => {
      const {
        data: { session },
      } = await supabase.auth.getSession();
      if (session) {
        const { data: profileData, error } = await supabase
          .from('profiles')
          .select('role')
          .eq('id', session.user.id)
          .single();

        if (error) {
          console.error('Error fetching user role:', error);
          setAuthState({ session, role: null, loading: false });
        } else {
          setAuthState({
            session,
            role: profileData?.role || null,
            loading: false,
          });
        }
      } else {
        setAuthState({ session: null, role: null, loading: false });
      }
    };

    fetchSessionAndRole();

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange(async (_event, session) => {
      if (session) {
        const { data: profileData, error } = await supabase
          .from('profiles')
          .select('role')
          .eq('id', session.user.id)
          .single();

        if (error) {
          console.error('Error fetching user role:', error);
          setAuthState({ session, role: null, loading: false });
        } else {
          setAuthState({
            session,
            role: profileData?.role || null,
            loading: false,
          });
        }
      } else {
        setAuthState({ session: null, role: null, loading: false });
      }
    });

    // Sincronización de estado entre pestañas
    const handleStorageChange = (e) => {
      if (e.key === 'supabase.auth.token') {
        fetchSessionAndRole();
      }
    };

    window.addEventListener('storage', handleStorageChange);

    // Revalidación periódica de la sesión
    const intervalId = setInterval(async () => {
      await fetchSessionAndRole();
    }, 60000);

    return () => {
      subscription.unsubscribe();
      window.removeEventListener('storage', handleStorageChange);
      clearInterval(intervalId);
    };
  }, []);

  return (
    <AuthContext.Provider value={authState}>{children}</AuthContext.Provider>
  );
};

export default AuthContext;
