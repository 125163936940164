// OrderHistory.js
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { supabase } from '../supabaseClient';
import { parseISO, format } from 'date-fns';
import { es } from 'date-fns/locale';
import { AnimatePresence } from 'framer-motion';
import {
  OrderHistoryContainer,
  Title,
  FilterContainer,
  OrderList,
  NoOrdersMessage,
  LoadingSpinner,
} from './OrderHistoryStyles';
import FilterSelect from './FilterSelect';
import OrderMonth from './OrderMonth';
import { useRealtimeOrders } from '../hooks/useRealtimeOrders';

const OrderHistory = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState({ year: '', month: '', day: '' });

  const fetchOrders = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const { data: ordersData, error: ordersError } = await supabase
        .from('orders')
        .select(
          `
          id, 
          status, 
          total_amount, 
          created_at, 
          order_details(
            product_id,
            quantity,
            foodproducts(id, name, image, price)
          )
        `
        )
        .order('created_at', { ascending: false });

      if (ordersError) throw ordersError;

      const ordersWithDetails = ordersData
        .filter((order) => order.total_amount > 0)
        .map((order) => ({
          ...order,
          items: order.order_details.map((item) => ({
            ...item,
            ...item.foodproducts,
            image: item.foodproducts.image || '/path/to/default-image.jpg',
          })),
        }));

      setOrders(ordersWithDetails);
    } catch (error) {
      console.error('Error fetching orders:', error);
      setError(
        `No se pudieron cargar las órdenes: ${error.message}. Por favor, intente de nuevo más tarde.`
      );
    } finally {
      setLoading(false);
    }
  }, []);

  useRealtimeOrders(fetchOrders);

  useEffect(() => {
    fetchOrders();
  }, [fetchOrders]);

  const filteredOrders = useMemo(() => {
    return orders.filter((order) => {
      const date = parseISO(order.created_at);
      const zonedDate = new Date(date.getTime() - 5 * 60 * 60 * 1000);
      const year = zonedDate.getFullYear();
      const month = zonedDate.getMonth() + 1;
      const day = zonedDate.getDate();
      return (
        (!filter.year || year === parseInt(filter.year)) &&
        (!filter.month || month === parseInt(filter.month)) &&
        (!filter.day || day === parseInt(filter.day))
      );
    });
  }, [orders, filter]);

  const handleFilterChange = useCallback((e) => {
    setFilter((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  }, []);

  const groupedOrders = useMemo(() => {
    const grouped = {};
    filteredOrders.forEach((order) => {
      const date = parseISO(order.created_at);
      const zonedDate = new Date(date.getTime() - 5 * 60 * 60 * 1000);
      const month = format(zonedDate, 'yyyy-MM', { locale: es });
      const day = format(zonedDate, 'yyyy-MM-dd', { locale: es });

      if (!grouped[month]) grouped[month] = {};
      if (!grouped[month][day]) grouped[month][day] = [];
      grouped[month][day].push(order);
    });

    return Object.keys(grouped)
      .sort((a, b) => new Date(b) - new Date(a))
      .reduce((acc, key) => {
        acc[key] = Object.keys(grouped[key])
          .sort((a, b) => new Date(b) - new Date(a))
          .reduce((acc2, key2) => {
            acc2[key2] = grouped[key][key2];
            return acc2;
          }, {});
        return acc;
      }, {});
  }, [filteredOrders]);

  const filterOptions = useMemo(() => {
    const years = [
      ...new Set(
        orders.map((order) => {
          const date = parseISO(order.created_at);
          const zonedDate = new Date(date.getTime() - 5 * 60 * 60 * 1000);
          return zonedDate.getFullYear();
        })
      ),
    ];

    const months = [
      ...new Set(
        orders.map((order) => {
          const date = parseISO(order.created_at);
          const zonedDate = new Date(date.getTime() - 5 * 60 * 60 * 1000);
          return zonedDate.getMonth() + 1;
        })
      ),
    ];

    let days;
    if (filter.year && filter.month) {
      days = [
        ...new Set(
          orders
            .filter((order) => {
              const date = parseISO(order.created_at);
              const zonedDate = new Date(date.getTime() - 5 * 60 * 60 * 1000);
              return (
                zonedDate.getFullYear() === parseInt(filter.year) &&
                zonedDate.getMonth() + 1 === parseInt(filter.month)
              );
            })
            .map((order) => {
              const date = parseISO(order.created_at);
              const zonedDate = new Date(date.getTime() - 5 * 60 * 60 * 1000);
              return zonedDate.getDate();
            })
        ),
      ];
    } else {
      days = [
        ...new Set(
          orders.map((order) => {
            const date = parseISO(order.created_at);
            const zonedDate = new Date(date.getTime() - 5 * 60 * 60 * 1000);
            return zonedDate.getDate();
          })
        ),
      ];
    }

    return {
      year: years.sort((a, b) => b - a),
      month: months.sort((a, b) => b - a),
      day: days.sort((a, b) => b - a),
    };
  }, [orders, filter.year, filter.month]);

  const formatDate = useCallback((dateString) => {
    const date = parseISO(dateString);
    const zonedDate = new Date(date.getTime() - 5 * 60 * 60 * 1000);
    return format(zonedDate, 'dd/MM/yyyy HH:mm:ss', {
      locale: es,
    });
  }, []);

  return (
    <OrderHistoryContainer initial='hidden' animate='visible'>
      <Title>Historial de Órdenes</Title>
      <FilterContainer role='group' aria-label='Filtros de órdenes'>
        {Object.entries(filterOptions).map(([key, options]) => (
          <FilterSelect
            key={key}
            name={key}
            value={filter[key]}
            onChange={handleFilterChange}
            options={options}
            aria-label={`Filtrar por ${key}`}
          />
        ))}
      </FilterContainer>
      <AnimatePresence>
        {loading ? (
          <LoadingSpinner
            animate={{ rotate: 360 }}
            transition={{ duration: 1, loop: Infinity, ease: 'linear' }}
          />
        ) : error ? (
          <NoOrdersMessage>{error}</NoOrdersMessage>
        ) : filteredOrders.length === 0 ? (
          <NoOrdersMessage>No hay órdenes disponibles.</NoOrdersMessage>
        ) : (
          <OrderList>
            {Object.entries(groupedOrders).map(([month, days], monthIndex) => (
              <OrderMonth
                key={month}
                month={month}
                days={days}
                monthIndex={monthIndex}
                formatDate={formatDate}
              />
            ))}
          </OrderList>
        )}
      </AnimatePresence>
    </OrderHistoryContainer>
  );
};

export default OrderHistory;
