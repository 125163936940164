import styled from 'styled-components';

export const AuthContainer = styled.div`
  max-width: 400px;
  margin: 0 auto;
  padding: 40px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family: 'Montserrat', sans-serif;
  color: var(--text-color);
  animation: fadeIn 0.5s ease-in-out;

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @media (max-width: 480px) {
    padding: 20px;
  }
`;

export const AuthLogo = styled.div`
  text-align: center;
  margin-bottom: 30px;

  img {
    max-width: 120px;
    transition: transform 0.3s;

    &:hover {
      transform: scale(1.1) rotate(5deg);
    }

    @media (max-width: 480px) {
      max-width: 80px;
    }
  }
`;

export const AuthTitle = styled.h2`
  text-align: center;
  margin-bottom: 30px;
  color: var(--primary-color);
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;

  @media (max-width: 480px) {
    font-size: 20px;
    margin-bottom: 20px;
  }
`;

export const AuthForm = styled.form`
  display: flex;
  flex-direction: column;
`;

export const AuthInputWrapper = styled.div`
  position: relative;
  margin-bottom: 20px;

  @media (max-width: 480px) {
    margin-bottom: 15px;
  }
`;

export const AuthInput = styled.input`
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  color: var(--text-color);
  background-color: #f5f5f5;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;

  &:focus {
    outline: none;
    box-shadow:
      0 2px 5px rgba(0, 0, 0, 0.2),
      0 0 0 3px rgba(231, 76, 60, 0.2);
  }

  &::placeholder {
    color: #999;
  }

  @media (max-width: 480px) {
    font-size: 14px;
    padding: 10px;
  }
`;

export const AuthButton = styled.button`
  display: block;
  width: 100%;
  padding: 12px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition:
    background-color 0.3s,
    transform 0.3s;

  &:hover {
    background-color: #c0392b;
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  &:active {
    transform: translateY(0);
    box-shadow: none;
  }

  @media (max-width: 480px) {
    font-size: 14px;
    padding: 10px;
  }
`;

export const AuthErrorMessage = styled.p`
  color: var(--primary-color);
  margin-bottom: 10px;
  font-size: 14px;
  animation: shake 0.5s ease-in-out;

  @keyframes shake {
    0%,
    100% {
      transform: translateX(0);
    }
    10%,
    30%,
    50%,
    70%,
    90% {
      transform: translateX(-5px);
    }
    20%,
    40%,
    60%,
    80% {
      transform: translateX(5px);
    }
  }

  @media (max-width: 480px) {
    font-size: 12px;
    margin-bottom: 5px;
  }
`;

export const AuthLink = styled.span`
  color: var(--primary-color);
  text-decoration: none;
  font-size: 14px;
  cursor: pointer;
  transition:
    color 0.3s ease,
    text-decoration 0.3s ease;
  position: relative;
  display: inline-block;
  margin-left: 4px;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -2px;
    left: 0;
    background-color: #c0392b;
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    color: #c0392b;

    &::after {
      visibility: visible;
      transform: scaleX(1);
    }
  }

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

export const AuthFooter = styled.footer`
  margin-top: 20px;
  text-align: center;

  p {
    margin-bottom: 5px;
  }
`;

export const PasswordToggle = styled.button`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: var(--primary-color);
  font-size: 16px;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: #c0392b;
  }

  i {
    transition: transform 0.3s ease;
  }

  &:hover i {
    transform: scale(1.2);
  }
`;
