import React from 'react';
import './CustomConfirmationModal.css';
import { FaCheckCircle } from 'react-icons/fa';

const CustomConfirmationModal = ({
  message,
  onConfirm,
  onCancel,
  orderConfirmed,
}) => {
  return (
    <div className='custom-modal'>
      <div className='custom-modal-content'>
        {orderConfirmed && (
          <div className='icon-container'>
            <FaCheckCircle className='icon-inner' />
          </div>
        )}
        {orderConfirmed && (
          <p className='confirmacion-title'>¡Orden confirmada con éxito!</p>
        )}
        <p>{message}</p>
        <div className='custom-modal-buttons'>
          <button className='btn btn-primary' onClick={onConfirm}>
            Nueva
          </button>
          <button className='btn btn-secondary' onClick={onCancel}>
            Salir
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomConfirmationModal;
