import React, { useState, useEffect, useCallback } from 'react';
import { createRoot } from 'react-dom/client';
import {
  BrowserRouter as Router,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import App from './App';
import AuthContext from './components/AuthContext';
import Auth from './components/Auth';
import { supabase } from './supabaseClient';

const AuthProvider = ({ children }) => {
  const [session, setSession] = useState(null);
  const [initializing, setInitializing] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  const handleRedirect = useCallback(
    (currentSession) => {
      if (currentSession && location.pathname === '/auth') {
        const redirectTo = new URLSearchParams(location.search).get(
          'redirectTo'
        );
        navigate(redirectTo || '/');
      }
    },
    [location.pathname, location.search, navigate]
  );

  useEffect(() => {
    let mounted = true;

    const fetchSession = async () => {
      try {
        const { data } = await supabase.auth.getSession();
        if (mounted) {
          setSession(data.session);
          handleRedirect(data.session);
          setInitializing(false);
        }
      } catch (error) {
        console.error('Error fetching session:', error);
        if (mounted) setInitializing(false);
      }
    };

    fetchSession();

    const { data: authListener } = supabase.auth.onAuthStateChange(
      (event, newSession) => {
        if (mounted) {
          setSession(newSession);
          handleRedirect(newSession);
        }
      }
    );

    return () => {
      mounted = false;
      authListener.subscription.unsubscribe();
    };
  }, [handleRedirect]);

  if (initializing) {
    return <div>Inicializando aplicación...</div>;
  }

  return (
    <AuthContext.Provider value={session}>
      {!session && location.pathname === '/auth' ? <Auth /> : children}
    </AuthContext.Provider>
  );
};

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <AuthProvider>
        <App />
      </AuthProvider>
    </Router>
  </React.StrictMode>
);
