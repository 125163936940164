import { useEffect, useCallback, useRef } from 'react';
import { supabase } from '../supabaseClient';

export const useRealtimeOrders = (fetchOrders) => {
  // Usar useRef para almacenar la función debounce
  const debounceTimerRef = useRef(null);

  // Crear una versión memoizada de fetchOrders
  const memoizedFetchOrders = useCallback(() => {
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
    }
    debounceTimerRef.current = setTimeout(() => {
      fetchOrders();
    }, 300);
  }, [fetchOrders]);

  useEffect(() => {
    const channel = supabase
      .channel('public:orders')
      .on(
        'postgres_changes',
        { event: '*', schema: 'public', table: 'orders' },
        (payload) => {
          console.log('Change received!', payload);
          memoizedFetchOrders();
        }
      )
      .subscribe((status) => {
        if (status === 'SUBSCRIBED') {
          console.log('Subscribed to orders changes');
        }
        if (status === 'CLOSED') {
          console.log('Subscription to orders changes closed');
        }
        if (status === 'CHANNEL_ERROR') {
          console.error('Error in orders subscription channel');
        }
      });

    return () => {
      console.log('Unsubscribing from orders changes');
      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current);
      }
      channel.unsubscribe();
    };
  }, [memoizedFetchOrders]);
};
