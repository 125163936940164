import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';
import {
  OrderMonth,
  MonthTitle,
  OrderDay,
  DayTitle,
  OrderItem,
  OrderInfo,
  ItemList,
} from './OrderHistoryStyles';
import { itemVariants } from './animationVariants';
import OrderItemComponent from './OrderItemComponent';

const OrderMonthComponent = memo(({ month, days, monthIndex, formatDate }) => (
  <OrderMonth
    variants={itemVariants}
    initial='hidden'
    animate='visible'
    transition={{ delay: monthIndex * 0.1 }}
  >
    <MonthTitle>
      {format(parseISO(`${month}-01`), 'MMMM yyyy', { locale: es })}
    </MonthTitle>
    {Object.entries(days).map(([day, dayOrders], dayIndex) => (
      <OrderDay
        key={day}
        variants={itemVariants}
        initial='hidden'
        animate='visible'
        transition={{ delay: dayIndex * 0.05 }}
      >
        <DayTitle>{format(parseISO(day), 'EEEE d', { locale: es })}</DayTitle>
        {dayOrders.map((order, orderIndex) => (
          <OrderItem
            key={order.id}
            variants={itemVariants}
            initial='hidden'
            animate='visible'
            transition={{ delay: orderIndex * 0.03 }}
          >
            <OrderInfo>Orden ID: {order.id}</OrderInfo>
            <OrderInfo>Estado: {order.status}</OrderInfo>
            <OrderInfo>Total: ${order.total_amount.toFixed(2)}</OrderInfo>
            <OrderInfo>Fecha: {formatDate(order.created_at)}</OrderInfo>
            <ItemList>
              {order.items?.map((item) => (
                <OrderItemComponent key={item.product_id} item={item} />
              ))}
            </ItemList>
          </OrderItem>
        ))}
      </OrderDay>
    ))}
  </OrderMonth>
));

OrderMonthComponent.propTypes = {
  month: PropTypes.string.isRequired,
  days: PropTypes.object.isRequired,
  monthIndex: PropTypes.number.isRequired,
  formatDate: PropTypes.func.isRequired,
};

OrderMonthComponent.displayName = 'OrderMonthComponent';

export default OrderMonthComponent;
