// src/supabaseClient.js
import { createClient } from '@supabase/supabase-js';

const supabaseUrl = 'https://qdtuqbqfbrcuscvtufqv.supabase.co';
const supabaseAnonKey =
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InFkdHVxYnFmYnJjdXNjdnR1ZnF2Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTAxODgzMTYsImV4cCI6MjAwNTc2NDMxNn0.8sq83O9CyZlNThBCb6jrilcAvJZ6jq9TIe1VZoIdibA';

export const supabase = createClient(supabaseUrl, supabaseAnonKey);
console.log('Supabase client created:', supabase);

export default supabase;
