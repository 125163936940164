import React, { useState } from 'react';
import './OrderSummary.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import ConfirmationModal from './ConfirmationModal';
import PropTypes from 'prop-types';

const OrderSummary = ({
  cart,
  removeFromCart,
  updateQuantity,
  calculateTotal,
  confirmOrder,
  resetCart,
  selectedProduct,
  setSelectedProduct,
  isConfirmingOrder,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState(null);

  const openModal = () => {
    setShowModal(true);
    setError(null);
  };

  const closeModal = () => {
    setShowModal(false);
    setError(null);
  };

  const handleConfirmOrder = async (customerData) => {
    setError(null);
    try {
      console.log(
        'Iniciando confirmación de orden con datos del cliente:',
        customerData
      );
      await confirmOrder(customerData);
      closeModal();
      resetCart();
      if (selectedProduct) {
        setSelectedProduct(null);
      }
    } catch (err) {
      console.error('Error al confirmar la orden:', err);
      setError(
        'Hubo un problema al confirmar la orden. Por favor, inténtalo de nuevo.'
      );
    }
  };

  return (
    <div className='order-summary'>
      <div className='orden-info'>
        <h2>Orden Actual</h2>
        <span className='order-info'>Items: {cart.length}</span>
      </div>
      <div className='subtotal'>
        <label>
          <input type='checkbox' checked readOnly />
          Subtotal
        </label>
        <span className='subtotal-amount'>${calculateTotal().toFixed(2)}</span>
      </div>
      <hr className='divider' />
      <button
        className='btn btn-primary'
        onClick={openModal}
        disabled={cart.length === 0 || isConfirmingOrder}
      >
        {isConfirmingOrder ? (
          <>
            <i className='fas fa-spinner fa-spin'></i> Procesando...
          </>
        ) : (
          <>
            Confirmar orden <i className='fas fa-check'></i>
          </>
        )}
      </button>
      {error && <p className='error-message'>{error}</p>}
      <hr className='divider' />
      {cart.length === 0 ? (
        <p className='empty-cart'>Sin items</p>
      ) : (
        <div className='cart-items'>
          <p>Lista de productos en la orden:</p>
          {cart.map((item) => (
            <div key={item.id} className='cart-item'>
              <div className='cart-item-content'>
                <div className='image-container'>
                  <img src={item.image} alt={item.name} />
                </div>
                <div className='item-details'>
                  <p className='item-details-text'>{item.name}</p>
                  <p className='item-details-text'>
                    Precio unitario: ${item.price.toFixed(2)}
                  </p>
                </div>
                <div className='item-price'>
                  ${(item.price * item.quantity).toFixed(2)}
                </div>
              </div>
              <div className='item-controls'>
                <button
                  onClick={() => removeFromCart(item.id)}
                  className='remove-button'
                >
                  <i className='fas fa-trash-alt'></i>
                </button>
                <div className='quantity-controls'>
                  <button
                    onClick={() => updateQuantity(item.id, item.quantity - 1)}
                    disabled={item.quantity === 1}
                  >
                    <i className='fas fa-minus'></i>
                  </button>
                  <span>{item.quantity}</span>
                  <button
                    onClick={() => updateQuantity(item.id, item.quantity + 1)}
                  >
                    <i className='fas fa-plus'></i>
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      {showModal && (
        <ConfirmationModal
          cart={cart}
          calculateTotal={calculateTotal}
          confirmOrder={handleConfirmOrder}
          closeModal={closeModal}
          isConfirmingOrder={isConfirmingOrder}
        />
      )}
    </div>
  );
};

OrderSummary.propTypes = {
  cart: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
      quantity: PropTypes.number.isRequired,
      image: PropTypes.string.isRequired,
    })
  ).isRequired,
  removeFromCart: PropTypes.func.isRequired,
  updateQuantity: PropTypes.func.isRequired,
  calculateTotal: PropTypes.func.isRequired,
  confirmOrder: PropTypes.func.isRequired,
  resetCart: PropTypes.func.isRequired,
  selectedProduct: PropTypes.object,
  setSelectedProduct: PropTypes.func.isRequired,
  isConfirmingOrder: PropTypes.bool.isRequired,
};

export default OrderSummary;
