import styled from 'styled-components';
import { motion } from 'framer-motion';

// Contenedor principal con diseño más limpio y moderno
export const OrderHistoryContainer = styled(motion.div)`
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

// Título principal
export const Title = styled.h1`
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2.5rem;
  color: #2c3e50;
  font-weight: 700;
`;

// Contenedor de filtros con diseño centrado y espaciado adecuado
export const FilterContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;
`;

// Estilo para los selectores
export const Select = styled.select`
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 8px;
  border: 1px solid #ccc;
  background-color: #f7f7f7;
  transition:
    background-color 0.3s,
    border-color 0.3s;

  &:focus {
    background-color: #ffffff;
    border-color: #3498db;
    outline: none;
  }
`;

// Lista de órdenes con un diseño de columna y espaciado entre elementos
export const OrderList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

// Contenedor para cada mes
export const OrderMonth = styled(motion.div)`
  background: #ffffff;
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
`;

// Título del mes
export const MonthTitle = styled.h2`
  font-size: 1.8rem;
  color: #34495e;
  margin-bottom: 1rem;
`;

// Contenedor para cada día
export const OrderDay = styled(motion.div)`
  background: #f8f9fa;
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1rem;
`;

// Título del día
export const DayTitle = styled.h3`
  font-size: 1.4rem;
  color: #7f8c8d;
  margin-bottom: 1rem;
`;

// Contenedor para cada orden
export const OrderItem = styled(motion.div)`
  background: #ffffff;
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
`;

// Información de la orden
export const OrderInfo = styled.p`
  margin: 0;
  font-size: 1rem;
  color: #7f8c8d;

  &:first-child {
    font-weight: 600;
    color: #2c3e50;
  }
`;

// Lista de items dentro de una orden
export const ItemList = styled.ul`
  padding: 0;
  list-style: none;
  margin-top: 1rem;
`;

// Contenedor para cada item
export const Item = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  border-bottom: 1px solid #ecf0f1;

  &:last-child {
    border-bottom: none;
  }
`;

// Detalles de cada item
export const ItemDetails = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

// Imagen del item
export const ItemImage = styled.img`
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 8px;
`;

// Texto del item
export const ItemText = styled.div`
  display: flex;
  flex-direction: column;
`;

// Nombre del item
export const ItemName = styled.span`
  font-weight: 600;
  color: #34495e;
`;

// Precio del item
export const ItemPrice = styled.span`
  color: #7f8c8d;
`;

// Cantidad del item
export const ItemQuantity = styled.span`
  font-weight: 600;
  color: #3498db;
  margin-right: 0.5rem;
`;

// Total del item
export const ItemTotal = styled.span`
  font-weight: 600;
  color: #2ecc71;
`;

// Mensaje cuando no hay órdenes disponibles
export const NoOrdersMessage = styled.p`
  text-align: center;
  font-size: 1.4rem;
  color: #7f8c8d;
  margin-top: 2rem;
`;

// Spinner de carga
export const LoadingSpinner = styled(motion.div)`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin: 2rem auto;
`;

// Nombres de los meses
export const monthNames = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];

export const NoImagePlaceholder = styled.div`
  width: 50px;
  height: 50px;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  color: #888;
  text-align: center;
`;
