// En OrderItemComponent.js
import React from 'react';
import PropTypes from 'prop-types';
import {
  Item,
  ItemDetails,
  ItemImage,
  ItemText,
  ItemName,
  ItemPrice,
  ItemQuantity,
  ItemTotal,
  NoImagePlaceholder,
} from './OrderHistoryStyles';

const OrderItemComponent = ({ item }) => (
  <Item>
    <ItemDetails>
      {item.image ? (
        <ItemImage src={item.image} alt={item.name} />
      ) : (
        <NoImagePlaceholder>No image available</NoImagePlaceholder>
      )}
      <ItemText>
        <ItemName>{item.name}</ItemName>
        <ItemPrice>${item.price.toFixed(2)} c/u</ItemPrice>
      </ItemText>
    </ItemDetails>
    <div>
      <ItemQuantity>x{item.quantity}</ItemQuantity>
      <ItemTotal>${(item.price * item.quantity).toFixed(2)}</ItemTotal>
    </div>
  </Item>
);

OrderItemComponent.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    image: PropTypes.string,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    quantity: PropTypes.number.isRequired,
  }).isRequired,
};

export default OrderItemComponent;
