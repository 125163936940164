import React, { useState, useEffect, useCallback, useContext } from 'react';
import './App.css';
import { supabase } from './supabaseClient';
import { Route, Routes, useNavigate, Navigate } from 'react-router-dom';
import AuthContext from './components/AuthContext';
import { AuthProvider } from './components/AuthProvider';
import ProductsGrid from './components/ProductsGrid';
import OrderSummary from './components/OrderSummary';
import CustomConfirmationModal from './components/CustomConfirmationModal';
import OrderHistory from './components/OrderHistory';
import Confirmation from './components/Confirmation';
import LandingPage from './components/LandingPage';
import Auth from './components/Auth';

const ProtectedRoute = ({ children, allowedRoles }) => {
  const { session, role } = useContext(AuthContext);

  if (!session) {
    return <Navigate to='/auth' replace />;
  }

  if (allowedRoles && !allowedRoles.includes(role)) {
    return <Navigate to='/unauthorized' replace />;
  }

  return children;
};

function AppContent() {
  const { loading } = useContext(AuthContext);
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [cart, setCart] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState({});
  const [showCustomModal, setShowCustomModal] = useState(false);
  const [productsLoading, setProductsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [orderError, setOrderError] = useState(null);
  const [isConfirmingOrder, setIsConfirmingOrder] = useState(false);
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const { data: authListener } = supabase.auth.onAuthStateChange(
      (event, session) => {
        console.log('Cambio de estado de autenticación:', event);
        console.log(
          'Nueva sesión:',
          session ? 'Autenticado' : 'No autenticado'
        );
        if (session) {
          console.log('Usuario ID:', session.user.id);
        }
      }
    );

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const checkSession = async () => {
      const {
        data: { session },
        error,
      } = await supabase.auth.getSession();
      if (error) {
        console.error('Error al obtener la sesión inicial:', error);
        setError('Error al obtener la sesión inicial');
        return;
      }
      console.log(
        'Estado inicial de la sesión:',
        session ? 'Autenticado' : 'No autenticado'
      );
      if (session) {
        console.log('User ID inicial:', session.user.id);
      } else {
        console.log('No hay sesión activa');
      }
    };

    checkSession();
  }, []);

  const fetchProducts = useCallback(async () => {
    console.log('Iniciando fetchProducts');
    setProductsLoading(true);
    try {
      const { data, error } = await supabase.from('foodproducts').select();
      if (error) throw error;
      setProducts(data);
      console.log('Productos obtenidos:', data);
    } catch (error) {
      console.error('Error fetching products:', error);
      setError('Error fetching products');
    } finally {
      setProductsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!loading) {
      fetchProducts();
    }
  }, [loading, fetchProducts]);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  const addToCart = useCallback((product) => {
    console.log('Añadiendo al carrito:', product);
    setCart((prevCart) => {
      const existingProduct = prevCart.find((item) => item.id === product.id);
      if (existingProduct) {
        return prevCart.map((item) =>
          item.id === product.id
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
      } else {
        return [...prevCart, { ...product, quantity: 1 }];
      }
    });
  }, []);

  const removeFromCart = useCallback((productId) => {
    console.log('Removiendo del carrito:', productId);
    setCart((prevCart) => prevCart.filter((item) => item.id !== productId));
    setSelectedProducts((prevSelected) => {
      const newSelected = { ...prevSelected };
      delete newSelected[productId];
      return newSelected;
    });
  }, []);

  const updateQuantity = useCallback(
    (productId, quantity) => {
      console.log('Actualizando cantidad:', productId, quantity);
      if (quantity <= 0) {
        removeFromCart(productId);
      } else {
        setCart((prevCart) =>
          prevCart.map((item) =>
            item.id === productId ? { ...item, quantity } : item
          )
        );
        setSelectedProducts((prevSelected) => ({
          ...prevSelected,
          [productId]: quantity,
        }));
      }
    },
    [removeFromCart]
  );

  const calculateTotal = useCallback(() => {
    return cart.reduce((total, item) => total + item.price * item.quantity, 0);
  }, [cart]);

  const resetCart = useCallback(() => {
    console.log('Reseteando carrito');
    setCart([]);
    setSelectedProducts({});
  }, []);

  const getSessionWithTimeout = useCallback(async (timeout = 5000) => {
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);

    try {
      const { data, error } = await supabase.auth.getSession({
        signal: controller.signal,
      });
      clearTimeout(id);
      if (error) throw error;
      return data;
    } catch (error) {
      if (error.name === 'AbortError') {
        console.error(
          'La obtención de la sesión ha excedido el tiempo de espera'
        );
        throw new Error('Timeout getting session');
      }
      throw error;
    }
  }, []);

  const checkSupabaseConnection = useCallback(async () => {
    try {
      console.log('Iniciando verificación de conexión con Supabase...');
      const { data, error } = await supabase
        .from('foodproducts')
        .select('count')
        .limit(1);
      if (error) throw error;
      console.log('Conexión a Supabase exitosa, datos recibidos:', data);
      return true;
    } catch (error) {
      console.error('Error de conexión a Supabase:', error);
      return false;
    }
  }, []);

  const retryOperation = useCallback(async (operation, maxRetries = 3) => {
    let lastError;
    for (let i = 0; i < maxRetries; i++) {
      try {
        console.log(`Intento ${i + 1} de la operación...`);
        const result = await operation();
        console.log('Operación exitosa:', result);
        return result;
      } catch (error) {
        console.error(`Intento ${i + 1} fallido:`, error);
        lastError = error;
        await new Promise((resolve) => setTimeout(resolve, 1000 * (i + 1)));
      }
    }
    console.error('Todos los intentos fallaron');
    throw lastError;
  }, []);

  const confirmOrder = useCallback(
    async (customerData) => {
      if (isConfirmingOrder) {
        console.log('Ya se está procesando una orden');
        return;
      }

      setIsConfirmingOrder(true);
      setOrderError(null);
      console.log('1. confirmOrder iniciado en AppContent/MainContent');

      const timeoutPromise = new Promise((_, reject) =>
        setTimeout(() => reject(new Error('Timeout en confirmOrder')), 60000)
      );

      try {
        await Promise.race([
          (async () => {
            console.log('2. Verificando conexión con Supabase...');
            const isConnected = await checkSupabaseConnection();
            if (!isConnected) {
              throw new Error('No se pudo establecer conexión con Supabase');
            }

            console.log('3. Intentando obtener la sesión...');
            const { session } = await getSessionWithTimeout();

            if (!session) {
              console.error('4. No hay sesión activa');
              throw new Error('No hay sesión activa');
            }

            console.log(
              '5. Sesión obtenida:',
              JSON.stringify(session, null, 2)
            );

            if (!session.user) {
              console.error('6. No hay sesión de usuario');
              throw new Error('No hay sesión de usuario');
            }

            console.log('7. User ID from session:', session.user.id);

            const totalAmount = calculateTotal();
            console.log('8. Total amount:', totalAmount);

            const orderDetails = cart.map((item) => ({
              product_id: item.id,
              quantity: item.quantity,
              price: item.price,
            }));
            console.log(
              '9. Order details:',
              JSON.stringify(orderDetails, null, 2)
            );

            const order = {
              user_id: session.user.id,
              total_amount: totalAmount,
              status: 'pending',
              order_details: orderDetails,
              datos_factura: customerData,
            };
            console.log(
              '10. Orden a insertar:',
              JSON.stringify(order, null, 2)
            );

            console.log('11. Iniciando inserción de la orden en Supabase...');
            const { data, error } = await retryOperation(() =>
              supabase.from('orders').insert([order]).select()
            );
            console.log('Inserción de la orden completada');

            console.log(
              '12. Respuesta completa de Supabase:',
              JSON.stringify({ data, error }, null, 2)
            );

            if (error) {
              console.error('13. Error al insertar la orden:', error);
              throw error;
            }

            if (!data || data.length === 0) {
              console.error(
                '14. No se recibieron datos después de la inserción'
              );
              throw new Error('No se recibieron datos después de la inserción');
            }

            console.log(
              '15. Orden confirmada:',
              JSON.stringify(data[0], null, 2)
            );
            setShowCustomModal(true);
            return data[0];
          })(),
          timeoutPromise,
        ]);
      } catch (error) {
        console.error('Error al confirmar el pedido:', error);
        console.error('Stack trace:', error.stack);
        setOrderError(
          'Hubo un error al procesar su orden. Por favor, inténtelo de nuevo.'
        );
        throw error;
      } finally {
        setIsConfirmingOrder(false);
      }
    },
    [
      isConfirmingOrder,
      cart,
      calculateTotal,
      checkSupabaseConnection,
      getSessionWithTimeout,
      retryOperation,
    ]
  );

  const resetSelectedProducts = useCallback(() => {
    console.log('Reseteando productos seleccionados');
    setSelectedProducts({});
    setSelectedProduct(null);
  }, []);

  const toggleProductSelection = useCallback(
    (product) => {
      console.log('Toggling selección de producto:', product);
      setSelectedProducts((prevSelected) => {
        const newSelected = { ...prevSelected };
        if (product.id in newSelected) {
          newSelected[product.id] += 1;
          updateQuantity(product.id, newSelected[product.id]);
        } else {
          newSelected[product.id] = 1;
          addToCart(product);
        }
        return newSelected;
      });
    },
    [addToCart, updateQuantity]
  );

  const handleNewOrder = useCallback(() => {
    console.log('Manejando nueva orden');
    resetSelectedProducts();
    setShowCustomModal(false);
    navigate('/products');
  }, [navigate, resetSelectedProducts]);

  const handleExit = useCallback(() => {
    console.log('Manejando salida');
    resetSelectedProducts();
    setShowCustomModal(false);
    navigate('/');
  }, [navigate, resetSelectedProducts]);

  if (loading || productsLoading) {
    return <div>Cargando...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!isOnline) {
    return (
      <div>
        No hay conexión a internet. Por favor, revise su conexión e intente de
        nuevo.
      </div>
    );
  }

  return (
    <div className='app-container'>
      {orderError && <div className='error-message'>{orderError}</div>}
      <Routes>
        <Route path='/' element={<LandingPage />} />
        <Route path='/auth' element={<Auth />} />
        <Route
          path='/products'
          element={
            <ProtectedRoute allowedRoles={['admin', 'manager', 'user']}>
              <div className='main-content'>
                <div className='products-section'>
                  <ProductsGrid
                    products={products}
                    onAddToCart={addToCart}
                    selectedProduct={selectedProduct}
                    setSelectedProduct={setSelectedProduct}
                    resetSelectedProducts={resetSelectedProducts}
                    selectedProducts={selectedProducts}
                    setSelectedProducts={setSelectedProducts}
                    toggleProductSelection={toggleProductSelection}
                  />
                </div>
                <div className='order-summary-section'>
                  <OrderSummary
                    cart={cart}
                    removeFromCart={removeFromCart}
                    updateQuantity={updateQuantity}
                    calculateTotal={calculateTotal}
                    confirmOrder={confirmOrder}
                    resetCart={resetCart}
                    selectedProduct={selectedProduct}
                    setSelectedProduct={setSelectedProduct}
                    isConfirmingOrder={isConfirmingOrder}
                  />
                </div>
              </div>
            </ProtectedRoute>
          }
        />
        <Route
          path='/order-history'
          element={
            <ProtectedRoute allowedRoles={['admin', 'manager']}>
              <OrderHistory />
            </ProtectedRoute>
          }
        />
        <Route path='/confirmation' element={<Confirmation />} />
        <Route
          path='/unauthorized'
          element={<div>No tienes permiso para acceder a esta página</div>}
        />
      </Routes>
      {showCustomModal && (
        <CustomConfirmationModal
          message='¿Desea crear una nueva orden o salir?'
          onConfirm={handleNewOrder}
          onCancel={handleExit}
          orderConfirmed={true}
        />
      )}
    </div>
  );
}

function App() {
  return (
    <AuthProvider>
      <AppContent />
    </AuthProvider>
  );
}

export default App;
