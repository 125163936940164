// FilterSelect.js
import React from 'react';
import PropTypes from 'prop-types';

const FilterSelect = ({ name, value, onChange, options }) => (
  <div>
    <label htmlFor={name}>
      {name.charAt(0).toUpperCase() + name.slice(1)}:
    </label>
    <select id={name} name={name} value={value} onChange={onChange}>
      <option value=''>Todos</option>
      {options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  </div>
);

FilterSelect.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
};

export default FilterSelect;
